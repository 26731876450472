import React from 'react';

import { connect } from 'react-redux';
import { connect as refetchConnect, PromiseState } from 'react-refetch';

import { withRouter, Link } from 'react-router-dom';
import { type RouteComponentProps } from 'react-router';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';

import { Typography, Button } from '@material-ui/core';

import { Helmet } from 'react-helmet';

import type { GenericDictionary } from '@catalogit/common/lib/types/index.js';
import { textTruncate } from '@catalogit/common/lib/constants/typography.js';

import { type MediaResponseType } from '@catalogit/common/lib/types/media.js';
import {
  getPublicThumbnail,
  getPlaceholderImage,
  getPublicImage,
  getContrastColor
} from '@catalogit/common/lib/utils/media-utils.js';

import type { HUBThunkDispatch, IStoreState, IAccount, IAccountState } from '../types/store.js';

import PageHeader, { paddingTop } from '../components/page-header.js';

import GradedImageBackgroundLink from '../components/gradient-image-background-link.js';

import { getBackgroundColor } from '../utils/theme.js';

import { withHUBContext, type HUBContextProps } from '../hub-context.js';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      ...paddingTop(theme)
    },

    container: {
      padding: theme.spacing(2),

      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        padding: theme.spacing(4),
        paddingTop: theme.spacing(2)
      }
    },

    collections: {},

    collectionGrid: {
      marginTop: theme.spacing(2),
      display: 'grid',
      gridGap: `${theme.spacing(2)}px`,
      gridTemplateColumns: 'repeat(auto-fill, minmax(18em, 1fr))',

      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(20em, 1fr))'
      }
    },

    collectionCell: {
      height: '22em',
      borderRadius: theme.spacing(4),
      backgroundColor: theme.palette.grey[200],
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2)
    },

    classifications: {
      flexGrow: 1,
      flexBasis: '40%',
      marginTop: theme.spacing(4),

      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: theme.spacing(4)
      }
    },

    classificationsGrid: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      flexShrink: 1,
      display: 'grid',
      gridGap: `${theme.spacing(2)}px`,
      gridTemplateColumns: 'repeat(auto-fill, minmax(16em, 1fr))',
      borderRadius: theme.spacing(4),
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(20em, 1fr))'
      }
    },

    classificationCell: {},

    exploreButton: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },

    lineClamp5: {
      position: 'relative',
      // height: '3.42858em',
      height: '7.8em',
      overflow: 'hidden',
      ...theme.typography.body2,
      // lineHeight: '1.71429em',
      lineHeight: '1.3em',

      '&:after': {
        content: '""',
        textAlign: 'right',
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '50%',
        // height: '1.71429em',
        height: '1.3em',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%)'
      }
    },

    /* Now add in code for the browsers that support -webkit-line-clamp and overwrite the non-supportive stuff */
    '@supports (-webkit-line-clamp: 2)': {
      lineClamp5: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical',
        height: 'auto',

        '&:after': {
          display: 'none'
        }
      }
    }
  });

type JSSProps = WithStyles<typeof styles, true>;

interface IReduxProps {
  accountState: IAccountState;
  dispatch: HUBThunkDispatch;
}

interface IClassificationMetric {
  classification: string;
  total: number;
  media: MediaResponseType[];
}

interface IFeaturedResponse {
  featured_accounts: IAccount[];
  featured_classifications: IClassificationMetric[];
}

interface IRefetchProps {
  featuredFetch?: PromiseState<IFeaturedResponse>;
}

interface IExternalProps {
  apiEndpoint: string;
}

interface IState {
  search: string;
}

type PropsType = IExternalProps & JSSProps & IReduxProps & IRefetchProps & RouteComponentProps<any>;

class Home extends React.Component<PropsType, IState> {
  constructor(props: PropsType, context: any) {
    super(props, context);

    this.state = {
      search: ''
    };
  }

  handleAccountClick = (e: React.SyntheticEvent<HTMLElement>) => {
    this.props.history.push(`/${e.currentTarget.dataset.id}`, { from: 'home' });
  };

  handleClassificationClick = (e: React.SyntheticEvent<HTMLElement>) => {
    const classification = e.currentTarget.dataset.id;
    if (!classification) {
      return;
    }
    this.props.history.push(`/classifications/${encodeURIComponent(classification)}`, {
      from: 'home',
      back: '/'
    });
  };

  handleSearch = (search: string) => {
    if (search) {
      this.props.history.push(`/search/${encodeURIComponent(search)}`, {
        from: 'home',
        back: '/'
      });
    }
  };

  render() {
    const { featuredFetch, classes, theme } = this.props;
    if (!featuredFetch) {
      return null;
    }

    if (featuredFetch.pending) {
      return <div style={{ margin: theme.spacing(2) }}>loading</div>;
    } else if (featuredFetch.rejected) {
      return (
        <div style={{ margin: theme.spacing(2) }}>
          <p>{`Errored: ${featuredFetch.reason}`}</p>
          <Button variant='contained' color='primary' onClick={() => (window.location.href = '/')}>
            Re-Try
          </Button>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>CatalogIt HUB</title>
        </Helmet>

        <PageHeader onSearch={this.handleSearch} variant='orange' />

        <div className={classes.content}>
          <div className={classes.container}>
            <div
              className={classes.collections}
              style={{
                flexGrow: 1,
                flexBasis: '60%'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: theme.spacing(0, 2)
                }}
              >
                <Typography variant='h5'>Collections</Typography>
                <Button
                  className={classes.exploreButton}
                  component={React.forwardRef((props: any, ref) => (
                    <Link ref={ref} to='/collections' {...props} />
                  ))}
                >
                  All Collections
                </Button>
              </div>

              <div className={classes.collectionGrid}>
                {featuredFetch.value.featured_accounts.map((a) => {
                  const { path: avatarPath } = a.avatar
                    ? getPublicThumbnail(a.avatar)
                    : getPlaceholderImage();

                  // default foreground and background colors to black & white
                  let bgColor = theme.palette.common.white;
                  let fgColor = theme.palette.common.black;
                  let bgPath;

                  const background: GenericDictionary = {
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundColor: '#999'
                  };

                  if (a.background) {
                    const { path, width: bgWidth, height: bgHeight } = getPublicImage(a.background);
                    bgPath = path;

                    background.backgroundImage = `url('${bgPath}')`;
                    background.backgroundSize = `${bgWidth}px ${bgHeight}px`;

                    bgColor = getBackgroundColor(a, a.background);
                    fgColor = getContrastColor(bgColor);

                    // add # for hex
                    bgColor = `#${bgColor}`;
                    fgColor = `#${fgColor}`;
                  }

                  return (
                    <div key={a.id} data-id={a.id} className={classes.collectionCell}>
                      <div
                        className='collectionInfo'
                        style={{
                          flexBasis: '100%',
                          flexGrow: 1,
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <GradedImageBackgroundLink
                          gotoURL={`/${a.id}`}
                          gotoState={{ back: '/' }}
                          imageURL={bgPath}
                          layoutStyle={{
                            flexBasis: '60%',
                            flexShrink: 0,
                            flexGrow: 0
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
                            }}
                          >
                            <img
                              src={avatarPath}
                              style={{
                                width: 75,
                                borderRadius: '50%'
                              }}
                              alt={a.name}
                            />
                            <div
                              style={{
                                color: theme.palette.common.white,
                                marginLeft: theme.spacing(2),
                                overflow: 'hidden'
                              }}
                            >
                              <Typography
                                variant='h5'
                                style={{
                                  color: 'inherit',
                                  whiteSpace: 'nowrap',
                                  ...textTruncate
                                }}
                              >
                                {a.name || '- anonymous -'}
                              </Typography>
                              <Typography
                                variant='body2'
                                style={{ fontWeight: 'bold', color: 'inherit' }}
                              >{`${a.total} items`}</Typography>
                              <Typography variant='body2' style={{ color: 'inherit' }}>
                                {a.location}
                              </Typography>
                            </div>
                          </div>
                        </GradedImageBackgroundLink>

                        <div
                          className={classes.lineClamp5}
                          style={{
                            marginTop: theme.spacing(2),
                            whiteSpace: 'pre-wrap',
                            flexGrow: 0,
                            flexShrink: 0
                          }}
                        >
                          {a.description}
                        </div>
                      </div>

                      {/* <div
                        className='folderInfo'
                        style={{
                          flexBasis: '40%',
                          flexShrink: 1
                        }}
                      >
                      </div> */}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes.classifications}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: theme.spacing(0, 2)
                }}
              >
                <Typography variant='h5'>Classifications</Typography>
                <Button
                  className={classes.exploreButton}
                  component={React.forwardRef((props: any, ref) => (
                    <Link ref={ref} to={'/classifications'} {...props} />
                  ))}
                >
                  All Classifications
                </Button>
              </div>

              <div className={classes.classificationsGrid}>
                {featuredFetch.value.featured_classifications.map(
                  ({ classification, total, media }) => {
                    const backgroundImage =
                      media && media.length > 0 ? getPublicImage(media[0]).path : undefined;

                    return (
                      <div
                        key={classification}
                        data-id={classification}
                        onClick={this.handleClassificationClick}
                        className={classes.classificationCell}
                        style={{
                          height: '10em'
                        }}
                      >
                        <GradedImageBackgroundLink
                          gotoURL={`/classifications/${encodeURIComponent(classification)}`}
                          gotoState={{ back: '/' }}
                          imageURL={backgroundImage}
                          layoutStyle={{
                            height: '10em'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              padding: theme.spacing(2),
                              border: '1px solid #ddd',
                              borderRadius: theme.spacing(2),
                              height: '100%'
                            }}
                          >
                            <Typography variant='h6' style={{ color: theme.palette.common.white }}>
                              {classification}
                            </Typography>
                            <Typography
                              variant='body2'
                              style={{
                                color: theme.palette.common.white,
                                fontWeight: 'bold'
                              }}
                            >
                              {`${total} items`}
                            </Typography>
                          </div>
                        </GradedImageBackgroundLink>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withHUBContext(
  withStyles(styles, { withTheme: true })(
    refetchConnect((props) => ({
      featuredFetch: {
        url: `${(props as any).apiEndpoint}/api/public/featured`,
        headers: { 'X-CIT-Origin': (props as HUBContextProps).xCITOrigin }
      }
    }))(
      withRouter(
        connect((state: IStoreState) => ({
          accountState: state.account
        }))(Home as any)
      )
    )
  )
);
